import { createGlobalStyle } from "styled-components";

// COLOUR PALLETTE
// Media Query Breakpoints

// @media (max-width: 1200px) {
// }
// @media (max-width: 768px) {
// }
// @media (max-width: 320px) {
// }

export default createGlobalStyle`

*, *::before, *::after {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

* {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  line-height: 1.7;
  font-size: 1.6rem;
}

img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

input, button, textarea, select {
  font: inherit;
}

h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  font-family: 'Poppins', sans-serif;
  user-select: text;
}
p{
  overflow-wrap: break-word;
  font-family: 'Mulish', sans-serif;
  user-select: text;
}

#root, #__next {
  isolation: isolate;
}

button{
  user-select: text;
  padding: 0 2.5rem;
  backface-visibility: hidden;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  background-color: transparent;
  transition: all 0.2s ease;
  :hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  0% {
    opacity: 1;
    transform: rotate(0);
  }
  50% {
    opacity: 0;
    transform: rotate(90deg);
  }
  51%{
    transform: rotate(0);
  }
  100% {
    opacity: 1;
  }
}
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
`;
