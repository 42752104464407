import styled from "styled-components";
import React, { useEffect, useState } from "react";
import famroom from "./compressed/famroom.jpg";

const FinalSection = ({ reference, thisRef, animationType, flexFlow }) => {
  const [isVisible, setIsVisible] = useState(false);

  const goToContact = () => {
    window.scrollTo({
      top: reference.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });
    observer.observe(thisRef.current);
    return () => {
      observer.unobserve(thisRef.current);
    };
  }, []);

  return (
    <StyledWhatWeDoSection ref={thisRef} flexFlow={flexFlow}>
      <StyledDescription isVisible={isVisible}>
        <StyledSpan isVisible={isVisible} animationType={animationType}>
          Great projects come together through the work of a team. We're here to
          listen to our clients, work with designers and architects, and
          collaborate with our expert trades.
        </StyledSpan>
        {/* <StyledCont>
          <button onClick={goToContact}>Contact</button>
        </StyledCont> */}
      </StyledDescription>
      <StyledBackground>
        <img src={famroom} alt="" />
      </StyledBackground>
    </StyledWhatWeDoSection>
  );
};

const StyledCont = styled.div`
  margin-top: 2rem;
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  padding: 0 5%;
  animation: ${(props) => (props.isVisible ? props.animationType : null)};
  @media (max-width: 768px) {
    padding: 0 2%;
  }
`;
const StyledWhatWeDoSection = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexFlow};
  height: 90vh;
  width: auto;
  margin: 1rem 13%;
  position: relative;
  transition: all 0.2s ease;
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 550px) {
    margin: 1rem;
  }
`;

const StyledBackground = styled.div`
  height: 100%;
  width: 60%;
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const StyledDescription = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  font-size: 2rem;
  z-index: 2;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(350, 350, 350, 0.5);
  animation: ${(props) => (props.isVisible ? "fadeIn 3s ease-out" : null)};
  @media (max-width: 1200px) {
    width: 100%;
  }
  button {
    font-size: 16px;
  }
`;
export default FinalSection;
