import deck from "./compressed/deck.jpg";
import deck2 from "./compressed/deck2.jpg";
import dining from "./compressed/dining.jpg";
import dining2 from "./compressed/dining2.jpg";
import door from "./compressed/door.jpg";
import Earnestfamroom from "./compressed/Earnestfamroom.jpg";
import exterior from "./compressed/exterior.jpg";
import exterior2 from "./compressed/exterior2.jpg";
import famroom from "./compressed/famroom.jpg";
import famroom2 from "./compressed/famroom2.jpg";
import famroom3 from "./compressed/famroom3.jpg";
import kitchen from "./compressed/kitchen.jpg";
import kitchen2 from "./compressed/kitchen2.jpg";
import kitchen3 from "./compressed/kitchen3.jpg";
import kitchen4 from "./compressed/kitchen4.jpg";
import kitchen5 from "./compressed/kitchen5.jpg";
import kitchen6 from "./compressed/kitchen6.jpg";
import kitchenHall from "./compressed/kitchenHall.jpg";
import kitchenLights from "./compressed/kitchenLights.jpg";
import kitchWallUnit from "./compressed/kitchWallUnit.jpg"
import smythebathroom1 from "./compressed/Smythebathroom1.jpg";
import smythebathroom2 from "./compressed/Smythebathroom2.jpg";

import smytheKitchen1 from "./compressed/Smythekitchen1.jpg";
import smytheKitchen2 from "./compressed/Smythekitchen2.jpg";
import smytheKitchen3 from "./compressed/Smythekitchen3.jpg";
import smytheKitchen5 from "./compressed/Smythekitchen5.jpg";

import living from "./compressed/living.jpg";
import loft from "./compressed/loft.jpg";
import loft2 from "./compressed/loft2.jpg";
import loft3 from "./compressed/loft3.jpg";
import loft4 from "./compressed/loft4.jpg";
import lower from "./compressed/lower.jpg";
import maguire from "./compressed/maguire1.jpg";
import maguire4 from "./compressed/maguire4.png";
import shower from "./compressed/shower.jpg";
import stairs from "./compressed/stairs.jpg";
import stgeorgedoor from "./compressed/stgeorgedoor.jpg";
import StGeorgeHallway from "./compressed/StGeorgeHallway.jpg";
import StGeorgeJorgewc from "./compressed/StGeorgeJorgewc.jpg";
import StGeorgeJorgewc2 from "./compressed/StGeorgeJorgewc2.jpg";
import StGeorgeKitchen1 from "./compressed/StGeorgeKitchen1.jpg";
import StGeorgeKitchen2 from "./compressed/StGeorgeKitchen2.jpg";
import tiles from "./compressed/tiles.jpg";

const images = [
  { src: deck, alt: "a beautifully constructed deck" },
  { src: deck2, alt: "another view of the deck" },
  { src: dining, alt: "elegantly designed dining room" },
  { src: dining2, alt: "another view of the dining room" },
  { src: door, alt: "a wooden door with intricate carvings" },
  { src: Earnestfamroom, alt: "the Earnest family room" },
  { src: exterior, alt: "the exterior of the house" },
  { src: exterior2, alt: "another view of the house exterior" },
  { src: famroom, alt: "the family room" },
  { src: famroom2, alt: "another view of the family room" },
  { src: famroom3, alt: "yet another view of the family room" },
  { src: kitchen, alt: "the kitchen with modern appliances" },
  { src: kitchen2, alt: "another view of the kitchen" },
  { src: kitchen3, alt: "a close-up of the kitchen counter" },
  { src: kitchen4, alt: "the kitchen with natural lighting" },
  { src: kitchen5, alt: "a view of the kitchen sink and faucet" },
  { src: kitchen6, alt: "a view of the kitchen with marble flooring" },
  { src: kitchenHall, alt: "the hallway leading to the kitchen" },
  { src: kitchenLights, alt: "the kitchen with pendant lights" },
  { src: kitchWallUnit, alt: "the kitchen wall unit with storage" },
  { src: smythebathroom1, alt: "bathroom" },
  { src: smythebathroom2, alt: "bathroom" },
  { src: smytheKitchen1, alt: "kitchen" },
  { src: smytheKitchen2, alt: "kitchen" },
  { src: smytheKitchen3, alt: "kitchen" },
  { src: smytheKitchen5, alt: "kitchen" },
  { src: living, alt: "the living room with comfortable seating" },
  { src: loft, alt: "the loft area with a study table" },
  { src: loft2, alt: "another view of the loft" },
  { src: loft3, alt: "a view of the loft with a bookshelf" },
  { src: loft4, alt: "the loft with a large window" },
  { src: lower, alt: "the lower level of the house" },
  { src: maguire, alt: "the Maguire room" },
  { src: maguire4, alt: "another view of the Maguire room" },
  { src: shower, alt: "the shower area with glass walls" },
  { src: stairs, alt: "the staircase with wooden railing" },
  { src: stgeorgedoor, alt: "the door to the St. George room" },
  { src: StGeorgeHallway, alt: "the hallway leading to the St. George room" },
  { src: StGeorgeJorgewc, alt: "the St. George room bathroom" },
  {
    src: StGeorgeJorgewc2,
    alt: "another view of the St. George room bathroom",
  },
  { src: StGeorgeKitchen1, alt: "the St. George room kitchen" },
  { src: StGeorgeKitchen2, alt: "another view of the St. George room kitchen" },
  { src: tiles, alt: "the tile flooring in the house" },

];

export default images;
