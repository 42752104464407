import styled from "styled-components";
import icon1 from "./assets/design.png";
import icon2 from "./assets/brickwall.png";
import icon3 from "./assets/customer-support.png";
import icon4 from "./assets/house.png";


const Contact = ({ getInTouch }) => {
  return (
    <StyledContact ref={getInTouch}>
      <StyledH2>Tell us about your project</StyledH2>
      <StyledList>
        <StyledLi><h4>Framing</h4> <img src={icon1}></img></StyledLi>
        <StyledLi><h4>Residential</h4><img src={icon4}></img></StyledLi>
        <StyledLi><h4>Foundations</h4><img src={icon2}></img></StyledLi>
        <StyledLi><h4>Commercial</h4><img src={icon3}></img></StyledLi>
      </StyledList>
      <StyledForm>
        <input type="text" id="input-name" placeholder="Name"></input>
        <input
          type="email"
          id="input-email"
          placeholder="Email address"
        ></input>
        <input type="text" id="input-subject" placeholder="Subject"></input>
        <textarea
          name="message"
          type="text"
          id="input-message"
          placeholder="Message"
        ></textarea>
        <button>Submit</button>
      </StyledForm>
    </StyledContact>
  );
};

const StyledList = styled.div`
  padding-top: 1rem;
  width: 30%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledLi = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
padding-top:2.8rem;
img{
  height: 4rem;
  margin-left: 3rem;
}
h4{
font-weight: 400;
}
`
const StyledH2 = styled.h2`
position: absolute;
top: 3rem;
left: 50%;
width: 60%;
font-weight: 200;
transform: translateX(-50%);
font-size: 4rem;
  @media (max-width: 768px) {
  font-size: 3rem;
  width: 90%;
  }

`

const StyledContact = styled.div`
  display: flex;
  position: relative;
  height: 75vh;
  background-color: #cbd8d0;
  margin: 1rem 13%;
  padding: 15rem 6rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 550px) {
    margin: 1rem;
  }
`;
const StyledForm = styled.form`
  width: 70%;
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  input,
  textarea {
    margin: 1.5rem;
    width: 80%;
  }
  button {
    margin: 3rem;
    width: 10rem;
    background-color: #eaeaea;
    :hover {
      background-color: #fff;
      transform: translateY(-2px);
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    input,
    textarea {
      margin: 1rem;
      width: 100%;
    }
  }
`;

export default Contact;
