import styled from "styled-components";
import Contact from "./Contact";
import GoogleReviews from "./GoogleReviews";
import Footer from "./Footer";
import LinktoGallery from "./LinktoGallery";
import WhoWeAre from "./WhoWeAre";
import FinalSection from "./FinalSection";
import { useRef } from "react";

const MainContent = ({ whatWeDoRef, whoWeAre, getInTouch, setGalleryView }) => {
  const reference1 = useRef(null);
  const reference2 = useRef(null);
  const reference3 = useRef(null);

  return (
    <StyledContent>
      <StyledWhatWeDo ref={whatWeDoRef}>
        <FinalSection
          thisRef={reference3}
          reference={getInTouch}
          animationType={"moveInLeft 2s ease-out"}
          flexFlow={"row"}
        />
        <LinktoGallery
          reference={whoWeAre}
          thisRef={reference1}
          animationType={"moveInRight 2s ease-out"}
          flexFlow={"row-reverse"}
          setGalleryView={setGalleryView}
        />
        <WhoWeAre whoWeAre={whoWeAre} reference={reference2} />
      </StyledWhatWeDo>
      <Contact getInTouch={getInTouch} />
      <GoogleReviews />
      <Footer />
    </StyledContent>
  );
};

const StyledWhatWeDo = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  transition: all 0.2s ease;
`;

const StyledContent = styled.div`
  margin-top: calc(100vh - 75px);
  padding-top: 6rem;
  text-align: center;
  background-color: rgb(234, 234, 234);
`;

export default MainContent;
