import styled from "styled-components";
import starIcon from "./assets/star.png";

const StyledReview = ({ name, stars, imgSrc, text }) => {
  const starArr = [];
  for (let i = 0; i < stars; i++) {
    starArr.push(i);
  }
  console.log(starArr);
  return (
    <ContentDiv>
      <NameStarDiv>
        <NameDiv>
          <img src={imgSrc} alt="pfp" />
          <p>{name}</p>
        </NameDiv>
        <StarDiv>
          {starArr.map((star, i) => {
            return <img key={i} src={starIcon} alt="google star" />;
          })}
        </StarDiv>
      </NameStarDiv>
      <StyledP>{text}</StyledP>
    </ContentDiv>
  );
};
const StyledP = styled.p`
  margin-top: 2rem;
`;
const NameStarDiv = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const NameDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-weight: bold;
    font-size: 2rem;
    padding-left: 1rem;
  }
`;
const StarDiv = styled.div`
  display: flex;
  img {
    height: 3rem;
    margin-left: 3px;
  }
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const ContentDiv = styled.div`
  padding: 2rem;
  margin: 2rem;
  max-width: 40rem;
  border: 2px solid black;
`;

export default StyledReview;
