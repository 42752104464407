import blue from "./assets/right-arrow-blue.png";
import black from "./assets/right-arrow-black.png";
import styled from "styled-components";

const Continue = ({ reference, color, }) => {

  const handleClick = (currRef) => {
    window.scrollTo({
      top: currRef.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <StyledCont onClick={() => handleClick(reference)}>
      Continue{" "}
      <span>
        <StyledArrow src={color === "blue" ? blue : black} />
      </span>
    </StyledCont>
  );
};
const StyledCont = styled.button`
  font-size: 16px;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  background-color: transparent;
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledArrow = styled.img`
  height: 30px;
  margin-left: 1rem;
`;

export default Continue;
