import styled from "styled-components";

const Footer = () => {
  return (
  <StyledFooter>
    <p>Copyright Graeme Cherry 2023</p>
    <p>Built by <a href="https://github.com/olliivver">Oliver</a></p>

  </StyledFooter> );
}

const StyledFooter = styled.footer`
height: 75px;
width: 100%;
display: flex;
text-align: center;
justify-content: center;
flex-direction: column;
a{
  text-decoration: none;
  color: black;
}
`
export default Footer;