import styled from "styled-components";
import StyledReview from "./StyledReview";
import j from "./assets/J.png";
import kamal from "./assets/Kamal.png";

const GoogleReviews = () => {
  return (
    <StyledReviews>
      <StyledH2>See what clients are saying</StyledH2>
      <ReviewDiv>
        <StyledReview
          name="Kamal"
          stars={5}
          imgSrc={kamal}
          text="Graeme Jay and Ali were all excellent. The work started and finished on time, only 6 months to demolish, excavate and build an addition to my home in downtown Toronto. I couldn’t be happier with the work."
        />
        <StyledReview
          name="Jenn"
          stars={5}
          imgSrc={j}
          text="Graeme and his crew did a great job with our home. Managing expectations, and delivering timely budget information helped with a complicated and long build. Thank you!!"
        />
      </ReviewDiv>
    </StyledReviews>
  );
};

const ReviewDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const StyledH2 = styled.h2`
  position: absolute;
  top: 3rem;
  left: 50%;
  width: 60%;
  font-weight: 200;
  transform: translateX(-50%);
  font-size: 4rem;
  @media (max-width: 768px) {
  font-size: 3rem;
  width: 90%;
  }

`;
const StyledReviews = styled.div`
  position: relative;
  height: auto;
  background-color: #fff;
  margin: 1rem 13%;
  padding: 18rem 6rem 18rem 6rem;
  @media (max-width: 550px) {
    margin: 1rem;
    padding: 13rem 2rem;
  }
`;

export default GoogleReviews;
