import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import Pagination from "./Paginate";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import images from "./Photos";
import styled from "styled-components";
import { useState, useRef } from "react";

const Gallery = ({ setGalleryView }) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const numCols = Math.floor((windowSize.current[0] - 200) / 210);
  const numRows = Math.floor((windowSize.current[1] - 200) / 130);
  const [imagesPerPage, setImagesPerPage] = useState(numCols * numRows);
  console.log(imagesPerPage);
  const [totalImages] = useState(images.length);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;

  const currentImages = images.slice(indexOfFirstImage, indexOfLastImage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onInit = () => {};
  return (
    <StyledGallery>
      <StyledClose onClick={() => setGalleryView(false)}>
        Close Gallery
      </StyledClose>
      <LightGallery
        onInit={onInit}
        speed={500}
        mode="lg-fade"
        openOnStart={1}
        openTimeout={1000}
        plugins={[lgThumbnail, lgZoom]}
      >
        {currentImages.map((image) => {
          return (
            <StyledThumb className="gallery-item" data-src={image.src}>
              <img
                src={image.src}
                alt={image.alt}
                className="img-responsive"
                style={{ maxWidth: "400px" }}
              />
            </StyledThumb>
          );
        })}
      </LightGallery>
      <Pagination
        paginate={paginate}
        imagesPerPage={imagesPerPage}
        totalImages={totalImages}
        currentPage={currentPage}
      />
    </StyledGallery>
  );
};
const StyledClose = styled.button`
  position: absolute;
  top: 2rem;
  right: 2rem;
`;
const StyledThumb = styled.a`
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 200px;
  height: 120px;

  overflow: hidden;
  transition: transform 0.2s ease;
  cursor: pointer;
  img {
    object-fit: cover;
    background-position: bottom;
    width: 100%;
    height: 100%;
  }
  :last-child {
    margin-right: 0;
  }
  :hover {
    transform: scale(1.05);
  }
`;

const StyledGallery = styled.div`
  background-color: #fff;
  position: fixed;
  padding: 10rem;
  z-index: 115;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  animation: swipe 1s ease-out;
  @keyframes swipe {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

export default Gallery;
