import styled from "styled-components";
import Carousel from "./Carousel";
import Continue from "./Continue";
import MainContent from "./MainContent";

const Homepage = ({ whatWeDoRef, whoWeAre, getInTouch, setGalleryView }) => {
  return (
    <>
      <Carousel />
      <StyledHomePage>
        <Styledh1Div>
          <StyledMainHead>
            <StyledGCBuild>GCBuild</StyledGCBuild>
            <StyledMainHeadSpan>
              We put clients at the
            </StyledMainHeadSpan>
            <StyledMainHeadSpan2>
            center of all we do
              <StyledPeriod>.</StyledPeriod>
            </StyledMainHeadSpan2>
          </StyledMainHead>
        </Styledh1Div>
        <ContinueDiv>
          <Continue reference={whatWeDoRef} color={"blue"} />
        </ContinueDiv>
      </StyledHomePage>
      <MainContent
      setGalleryView={setGalleryView}
        whatWeDoRef={whatWeDoRef}
        whoWeAre={whoWeAre}
        getInTouch={getInTouch}
      />
    </>
  );
};

const StyledHomePage = styled.div`
  position: absolute;
  color: #181d31;
  z-index: 5;
  top: 0;
  background-image: linear-gradient(
    to right bottom,
    rgb(24, 29, 49, 0.4),
    rgb(246, 247, 247, 0.4)
  );
  height: 100vh;
  width: 100%;
`;

const StyledGCBuild = styled.span`
  display: none;
`;

const Styledh1Div = styled.div`
  background-color: #fff;
  animation: fadeIn 5s ease-out;
  position: absolute;
  right: 0;
  bottom: 10%;
  padding: 1rem 5rem;
  border-radius: 3px;
  width: auto;
  height: auto;
  @media (max-width: 550px) {
    padding: 1rem 2rem;
  }
`;
const StyledMainHead = styled.h1`
  font-weight: 300;
  font-size: 7rem;
  display: block;
  @media (max-width: 1200px) {
    font-size: 6rem;
  }
  @media (max-width: 768px) {
    font-size: 4rem;
  }
  @media (max-width: 600px) {
    font-size: 3rem;
  }
  @media (max-width: 425px) {
    font-size: 2rem;
  }
`;

const StyledMainHeadSpan = styled.span`
  display: block;
  animation: moveInLeft 3s ease-out;
`;
const StyledMainHeadSpan2 = styled.span`
  display: block;
  animation: moveInRight 3s ease-out;
`;

const StyledPeriod = styled.span`
  color: #3b2763;
`;

const ContinueDiv = styled.div`
  position: absolute;
  top: 75%;
  left: 13%;
  @media (max-width: 1200px) {
    display: none;
  }
  @media (max-width: 550px) {
    left: 1rem;
  }
  @media (max-width: 320px) {
    top: 65%;
    left: 1%;
  }
  background-color: rgb(246, 247, 247, 0.6);
  border-radius: 100px;
  animation: fadeIn 5s ease-out;
  animation-delay: 3s;
  animation-fill-mode: both;
`;

// const StyledHomePage = styled.div`
//   position: absolute;
//   color: #181d31;
//   z-index: 5;
//   top: 0;
//   background-image: linear-gradient(
//     to right bottom,
//     rgb(246, 247, 247, 0.4),
//     rgb(24, 29, 49, 0.4)
//   );
//   height: 100vh;
//   width: 100%;
// `;
// const StyledGCBuild = styled.span`
//   display: none;
// `;

// const Styledh1Div = styled.div`
//   background-color: rgb(246, 247, 247, 0.4);
//   animation: fadeIn 5s ease-out;
//   position: absolute;
//   top: 20%;
//   left: 13%;
//   padding: 1rem 5rem;
//   border-radius: 3px;
//   transform: translateX(-5rem);
//   width: auto;
//   height: auto;
//   @media (max-width: 768px) {
//     top: 30%;
//     left: 13%;
//   }
//   @media (max-width: 550px) {
//     left: 50%;
//     transform: translateX(-50%);
//     width: 100%;
//     padding: 1rem 2rem;
//   }

// `;

// const StyledMainHead = styled.h1`
//   font-weight: 600;
//   font-size: 9.3rem;
//   display: block;
//   @media (max-width: 768px) {
//     font-size: 6rem;
//   }
//   @media (max-width: 600px) {
//     font-size: 5rem;
//   }
//   @media (max-width: 425px) {
//     font-size: 4rem;
//   }
// `;

// const StyledMainHeadSpan = styled.span`
//   display: block;
//   animation: moveInLeft 3s ease-out;
// `;
// const StyledMainHeadSpan2 = styled.span`
//   display: block;
//   animation: moveInRight 3s ease-out;
// `;

export default Homepage;
