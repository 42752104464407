import styled from "styled-components";
import hamburger from "./assets/hamburger.png";
import close from "./assets/close.png";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

const Header = ({ whatWeDoRef, getInTouch, whoWeAre, setGalleryView, galleryView }) => {
  const navigate = useNavigate();
  const handleClick = (currRef) => {
    if (currRef === "gcBuild") {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: currRef.current.offsetTop - 85,
        behavior: "smooth",
      });
    }
  };
  const [isOpen, setIsOpen] = useState(false);
  const [animated, setAnimated] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.pageYOffset);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const menuClick = () => {
    setAnimated(true);
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, 300);
    setTimeout(() => {
      setAnimated(false);
    }, 500);
  };

  return (
    <StyledHeader scrollPosition={scrollPosition}>
      <StyledH2 onClick={() => handleClick("gcBuild")}>
        <StyledH2Span>GC</StyledH2Span>
        <StyledH2Span2>Build</StyledH2Span2>
      </StyledH2>
      <StyledBurger
        src={!isOpen ? hamburger : close}
        className={animated ? "animated" : ""}
        onClick={menuClick}
        alt="hamburger-menu-icon click-to-use"
      />
      <SubMenu isOpen={isOpen}>
          <StyledButton onClick={()=>setGalleryView(!galleryView)}>Our Work</StyledButton>
        <StyledButton onClick={() => handleClick(whoWeAre)}>
          Who We Are
        </StyledButton>
        <StyledButton onClick={() => handleClick(getInTouch)}>
          Get in Touch
        </StyledButton>
      </SubMenu>
    </StyledHeader>
  );
};

const StyledHeader = styled.div`
  transition: background-color 1s ease-in-out;
  background-color: rgb(
    310,
    310,
    310,
    ${(props) => (props.scrollPosition > 100 ? 0.9 : 0.6)}
  );
  z-index: 10;
  height: 75px;
  padding: 0 13%;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .animated {
    animation: rotate 0.5s ease-in-out;
  }

  @media (max-width: 550px) {
    padding: 0 1rem;
  }
`;

const StyledBurger = styled.img`
  cursor: pointer;
  height: 4rem;
  position: absolute;
  right: 13%;
  display: none;

  @media (max-width: 1200px) {
    display: flex;
  }
  @media (max-width: 768px) {
    display: flex;
  }
  @media (max-width: 550px) {
    right: 1rem;
  }
  @media (max-width: 320px) {
    display: flex;
  }
`;

const StyledH2 = styled.h2`
  font-family: "Mulish", sans-serif;
  cursor: pointer;
  font-size: 4rem;
`;
const StyledH2Span = styled.span`
  color: #3b2763;
  font-weight: 800;
`;
const StyledH2Span2 = styled.span`
  font-style: italic;
  font-weight: 300;
  color: #181d31;
`;

const SubMenu = styled.div`
  display: flex;

  @media (max-width: 1200px) {
    transition: 0.5s all ease;
    display: ${(props) => (props.isOpen ? "flex" : "none")};
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
    flex-direction: column;
    position: absolute;
    top: 7.5rem;
    right: 13%;
    border: 1px solid black;
    padding: 1rem;
    background-color: rgb(310, 310, 310, 0.8);
    border-top: none;
    button {
      margin: 0.5rem;
    }
  }
  @media (max-width: 768px) {
    background-color: rgb(310, 310, 310, 1);
  }
  @media (max-width: 550px) {
    right: 1rem;
  }
`;
const StyledButton = styled.button`
  margin-left: 2rem;
  letter-spacing: 0.1rem;
  :hover {
    background-color: rgb(310, 310, 310, 0.3);
    transform: translateY(-2px);
  }
`;

export default Header;
