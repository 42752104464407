import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalStyles from "./GlobalStyles";
import Homepage from "./Homepage";
import Gallery from "./Gallery";
import Header from "./Header";
import { useRef, useState } from "react";


const App = () => {
  const whatWeDoRef = useRef(null);
  const whoWeAre = useRef(null);
  const getInTouch = useRef(null);

  const [galleryView, setGalleryView] = useState(false);

  function handleCloseGallery() {
    setGalleryView(false);
  }

  return (
    <div>
      <BrowserRouter>
        <GlobalStyles />
        <Header whatWeDoRef={whatWeDoRef} whoWeAre={whoWeAre} getInTouch={getInTouch} setGalleryView={setGalleryView} galleryView={galleryView} />
        <Homepage whatWeDoRef={whatWeDoRef} whoWeAre={whoWeAre} getInTouch={getInTouch} setGalleryView={setGalleryView}/>
        {galleryView && <Gallery setGalleryView={setGalleryView}/>}
      </BrowserRouter>
    </div>
  );
}

export default App;
