import styled from "styled-components";
import family from "./assets/family.jpg";

const WhoWeAre = ({ whoWeAre, reference }) => {
  return (
    <StyledWhoWeAre ref={whoWeAre}>
      <StyledImageDiv>
        <img src={family} alt="Graeme, the head of the contracting team" />
      </StyledImageDiv>
      <StyledContent>
        Committed. Professional. With a diverse set of experiences,
        qualifications, and skill sets. Meet the team.
      </StyledContent>
    </StyledWhoWeAre>
  );
};

const StyledWhoWeAre = styled.div`
  background-color: #fff;
  margin: 1rem 13%;
  height: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1200px) {
  }
  @media (max-width: 550px) {
    margin: 1rem;
    padding: 1rem;
  }
`;

const StyledContent = styled.p`
  padding: 4rem 13%;
  font-size: 2rem;
  height: 20%;
  @media (max-width: 1200px) {
    height: 30%;
  }
  @media (max-width: 798px) {
    padding: 1rem;
    height: 40%;
  }
`;

const StyledImageDiv = styled.div`
  width: 100%;
  height: 80%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  img {
    object-fit: cover;
    border-radius: 2px;
    background-position: top left;
  }
  @media (max-width: 1200px) {
    height: 70%;
  }
  @media (max-width: 798px) {
    height: 60%;
  }
`;

export default WhoWeAre;
