import React, { useState, useEffect, useRef, useMemo } from "react";
import styled from "styled-components";

import outside from "./assets/outside.JPG";
import interior from "./assets/interior.jpg";
import maguire from "./assets/maguire.jpg";

const Carousel = () => {
  const [offset, setOffset] = useState(0);
  const [caption, setCaption] = useState("");
  const parallaxContainer = useRef();

  const imgCarousel = useMemo(() => {
    return [outside, interior, maguire].map((url) => (
      <img key={url} src={url} />
    ));
  }, []);

  const maguireCaption = " Dundas West, Toronto.";
  const interiorCaption = "High Park, Toronto.";
  const outsideCaption = "Caledon, ON.";

  const [currentImageIndex, setCurrentImageIndex] = useState(
    Math.floor(Math.random() * imgCarousel.length)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((currentImageIndex + 1) % imgCarousel.length);
      if (currentImageIndex === 0) {
        setCaption( interiorCaption);
      }else if( currentImageIndex === 1){
        setCaption(maguireCaption)
      } else {
        setCaption(outsideCaption)
      }
    }, 10000);
    const handleScroll = () => {
      setOffset(window.pageYOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentImageIndex, imgCarousel]);

  return (
    <StyledCarousel
      ref={parallaxContainer}
      imgCarousel={imgCarousel}
      currentImageIndex={currentImageIndex}
      offset={offset}
    >
      <StyledCaption caption={caption} interiorCaption={interiorCaption}>{caption}</StyledCaption>
    </StyledCarousel>
  );
};

const StyledCaption = styled.div`
color: ${(props) => props.caption === props.interiorCaption ? "#fff" : null};
position: absolute;
bottom: 1rem;
left: 50%;
transform: translateX(-50%);
`
const StyledCarousel = styled.div`
  background-image: url(${(props) =>props.imgCarousel[props.currentImageIndex].props.src});
  top: ${(props) => props.offset * 0.5}px;
  position: absolute;
  display: block;
  background-size: cover;
  background-position: center;
  z-index: -2;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  transition: background-image 1s ease-in-out;
`;


export default Carousel;
